







































import { Component, Vue } from "vue-property-decorator";
import { ColumnTableCustom } from "@/models/interface/util.interfase";
export interface Dropdown {
  key?: number;
  name?: string;
  id?: string;
  available?: number;
}
const Props = Vue.extend({
  props: [
    "handleInputTableUnitCode",
    "dataTableUnitCode",
    "addRow",
    "showConfirmation",
    "selectedRowKeysTableUnitCode",
    "onSelectChangeTableUnitCode",
    "disabled",
    "handleSelectTableUnitCode",
    "handleSearchSelectTableUnitCode",
    "getCheckboxPropsTableUnitCode",
    "handleDateMonthUnitCode",
  ],
});

/**
 * @deprecated
 * new component {@linkcode FormUnitCode.vue}
 */
@Component
export default class TableDetailUnitCode extends Props {
  columnsTable = [
    {
      title: this.$t("lbl_unit_code"),
      dataIndex: "unitCode",
      key: "unitCode",
      width: 200,
      scopedSlots: { customRender: "unitCode" },
      responsiveColSelect: [
        {
          name: "unitCode",
          placeholder: this.$t("lbl_unit_code_placeholder"),
          value: "id",
          options: [] as any[],
          style: "width: 100%",
        },
      ],
      responsiveColDateMonth: [
        {
          name: "datePaymentInvoice",
          placeholder: this.$t("lbl_date_payment_invoice"),
          style: "width: 100%;",
        },
      ],
      responsiveColInput: [
        {
          name: "taxInvoiceNumberPayment",
          placeholder: this.$t("lbl_tax_invoice_number_payment"),
          style: "width: 100%;",
        },
        {
          name: "percent",
          placeholder: this.$t("lbl_precent_sort_placeholder"),
          style: "width: 100%;",
        },
        {
          name: "cogsPriceUnit",
          placeholder: this.$t("lbl_cogs_price_unit_placeholder"),
          style: "width: 100%;",
        },
      ],
    },
    {
      title: this.$t("lbl_tax_invoice_number_dp"),
      dataIndex: "taxInvoiceNumber",
      key: "taxInvoiceNumber",
      width: 300,
      scopedSlots: { customRender: "isNull" },
    },
    {
      title: this.$t("lbl_date"),
      dataIndex: "date",
      key: "date",
      width: 300,
      scopedSlots: { customRender: "isNull" },
    },
    {
      title: this.$t("lbl_tax_invoice_number_payment"),
      dataIndex: "taxInvoiceNumberPayment",
      key: "taxInvoiceNumberPayment",
      width: 300,
      scopedSlots: { customRender: "taxInvoiceNumberPayment" },
    },
    {
      title: this.$t("lbl_date_payment_invoice"),
      dataIndex: "datePaymentInvoice",
      key: "datePaymentInvoice",
      width: 300,
      scopedSlots: { customRender: "datePaymentInvoice" },
    },
    {
      title: this.$t("lbl_repayment_percentage"),
      dataIndex: "percent",
      key: "percent",
      width: 150,
      scopedSlots: { customRender: "percent" },
    },
    {
      title: this.$t("lbl_serial_number"),
      dataIndex: "serialNumber",
      key: "serialNumber",
      width: 250,
      scopedSlots: { customRender: "isNull" },
    },
    {
      title: this.$t("lbl_merk"),
      dataIndex: "merk",
      key: "merk",
      width: 150,
      scopedSlots: { customRender: "isNull" },
    },
    {
      title: this.$t("lbl_type"),
      dataIndex: "type",
      key: "type",
      width: 150,
      scopedSlots: { customRender: "isNull" },
    },
    {
      title: this.$t("lbl_cost_price_unit"),
      dataIndex: "cogsPriceUnit",
      key: "cogsPriceUnit",
      width: 300,
      scopedSlots: { customRender: "cogsPriceUnit" },
    },
    {
      title: this.$t("lbl_nett_finance"),
      dataIndex: "nettFinance",
      key: "nettFinance",
      width: 250,
      scopedSlots: { customRender: "isNull" },
    },
    {
      title: this.$t("lbl_interest"),
      dataIndex: "interest",
      key: "interest",
      width: 200,
      scopedSlots: { customRender: "isNull" },
    },
    {
      title: this.$t("lbl_provision"),
      dataIndex: "provisi",
      key: "provisi",
      width: 250,
      scopedSlots: { customRender: "isNull" },
    },
    {
      title: this.$t("lbl_insurance"),
      dataIndex: "insurance",
      key: "insurance",
      width: 200,
      scopedSlots: { customRender: "isNull" },
    },
    {
      title: this.$t("lbl_residue"),
      dataIndex: "residue",
      key: "residue",
      width: 150,
      scopedSlots: { customRender: "isNull" },
    },
  ] as ColumnTableCustom[];
}
