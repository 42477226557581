var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "a-row",
        [
          _c(
            "a-col",
            { attrs: { span: 24 } },
            [
              _c(
                "a-space",
                [
                  _c(
                    "a-button",
                    {
                      attrs: { type: "primary", disabled: _vm.disabled.addRow },
                      on: {
                        click: function($event) {
                          return _vm.addRow()
                        }
                      }
                    },
                    [_vm._v("Add Row")]
                  ),
                  _c(
                    "a-button",
                    {
                      attrs: {
                        type: "danger",
                        disabled: _vm.disabled.deleteRow
                      },
                      on: {
                        click: function($event) {
                          return _vm.showConfirmation()
                        }
                      }
                    },
                    [_vm._v(_vm._s(_vm.$t("lbl_delete_row")))]
                  )
                ],
                1
              ),
              _c("TableCustom", {
                staticStyle: { "margin-top": "4px" },
                attrs: {
                  idtable: "table1",
                  dataSource: _vm.dataTableUnitCode,
                  columns: _vm.columnsTable,
                  scroll: { x: "calc(700px + 50%)" },
                  paginationcustom: false,
                  defaultPagination: {
                    showTotal: function(total) {
                      return _vm.$t("lbl_total_items", { total: total })
                    }
                  },
                  getCheckboxProps: _vm.getCheckboxPropsTableUnitCode,
                  handleSearchSelectTable: _vm.handleSearchSelectTableUnitCode,
                  selectedRowKeys: _vm.selectedRowKeysTableUnitCode,
                  onSelectChange: _vm.onSelectChangeTableUnitCode,
                  handleSelect: _vm.handleSelectTableUnitCode,
                  handleInput: _vm.handleInputTableUnitCode,
                  handleDateMonth: _vm.handleDateMonthUnitCode
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }